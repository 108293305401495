/* eslint-disable @next/next/no-img-element */
import { Link } from '@/components';
import ArrowRight from 'assets/arrowRight.svg';

// pages/500.js
export default function Custom500({ errorCode, errorMessage }) {
  return (
    <div className="h-screen flex items-center justify-center relative">
      <div className="w-full h-full max-h-screen absolute z-[-1]">
        <img
          src="/images/500PageBg.png"
          width="1024"
          height="768"
          alt="500 Page Background"
          className="object-cover xxl:object-bottom h-full w-full"
        />
      </div>
      <div className="py-12 px-10 bg-brand-soft-white rounded w-[448px] flex flex-col gap-12 items-start">
        <h3 className="text-center font-didot italic text-title-xl leading-normal font-normal -tracking-card-m w-full">
          {errorCode || 500}
        </h3>
        <div className="font-montserrat">
          <p className="text-sm font-normal leading-6">{errorMessage || 'Internal server error'}</p>
        </div>
        <div className="text-center font-montserrat">
          <Link link={{ href: '/' }} className="btn primary border-brand-black/25 w-full mb-4 group">
            Back to homepage
            <ArrowRight
              className="!ml-6 !stroke-transparent !fill-brand-black group-hover:!fill-brand-soft-white"
              role="presentation"
            />
          </Link>
          <p className="text-sm leading-6 font-normal">
            If you need assistance, please contact
            <a href="mailto:marketing@maxxroyal.com"> marketing@maxxroyal.com.</a>
          </p>
        </div>
      </div>
    </div>
  );
}
